<template>
    <v-card class="mt-10">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation v-if="categories !== null" >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Title</strong></label>
                            <v-text-field
                            v-model="title"
                            :rules="nameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>

                            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for=""><strong>Category</strong></label>
                            <v-select
                                v-model="category"
                                :items="categories"
                                :rules="[v => !!v || 'Category is required']"
                                item-text="category"
                                item-value="id"
                                required
                                outlined
                                dense
                                ></v-select>
                         </v-col>
    
                         <v-col cols="12" lg="12" md="12 " sm="12" xs="12" class="mb-5">
                              <label for=""><strong>Display content</strong></label>
                               <tiptap-vuetify
                                    v-model="snippet"
                                    :extensions="extensions"
                                    placeholder="Enter display text"
                                    @keydown="onkeydown"
                                    class="mt-2 editor-class"
                                    />
                        </v-col>
                        <v-col cols="12" lg="12" md="12 " sm="12" xs="12" >
                              <label for=""><strong>Blog content</strong></label>
                               <tiptap-vuetify
                                    v-model="content"
                                    :extensions="extensions"
                                    placeholder="Write blog content  …"
                                    @keydown="onkeydown"
                                    class="mt-2 editor-class"
                                    />
                                   
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn
                                color="blue darken-1"
                                dark
                                @click="goBack()"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                            :disabled="!valid || loading"
                            :color="`${zamuYellow3}`"
                            class="mr-4 zamu-sub-heading "
                            @click="save"
                            dark
                            >
                            Submit
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import {  mapGetters } from 'vuex';

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';

import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Image } from 'tiptap-vuetify'


export default {
    name: 'eEditFAQ',
    components: { TiptapVuetify },

    mixins: [coreMixin, colorMixin],

     data () {
       return {
            post: null,
            categoryData: null,
            formTitle: 'Edit FAQ Post',
             valid: false,
             loading: false,
             sessionData: null,
             postID: null,
            title: '',
            notification: '',
            actionClass: '',
            image: '',
            snippet: '',
            extensions: [
                History,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Image,
               
                [Bold, {
                    renderIn: 'toolbar'
                }],
                [Blockquote, {
                    renderIn: 'toolbar',
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                [Link, {
                    renderIn: 'toolbar'
                }],
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            content: '',
            categories: [],
            category: '',

       };
    },
      computed: {
        ...mapGetters(['getSessionData']),
    },
    watch: {
        getSessionData(data) {
            this.sessionData = data;

        },
    },
     async mounted() {
         const id = this.$route.params.id;
         this.postID = id;
         this.post = await this.retrieveFAQPost(id);
        const categoryData = await this.retrieveFAQCategories();
        this.categories = categoryData.data;
         this.snippet = this.post !== null ? this.post.snippet : '';
         this.title = this.post !== null ? this.post.title : '';
         this.content = this.post !== null ? this.post.content : '';
         this.category = this.post.category;


    },
    methods: {
        onkeydown (event) {
          return event;
         },
        async save(){
              const formvalid = this.$refs.form.validate();
              if(!formvalid) {
                  return;
              }

                this.loading = true;

                    const payload = {
                        title: this.title,
                        content: this.content,
                        snippet: this.snippet,
                      };
                      const fullPayload = {
                          app: this.apiUrl,
                          params: payload,
                          endpoint: `FAQs/update/${this.postID}`,
                      };
                        try {
                          const res = await this.axiosPutRequest(fullPayload);
                          this.loading = false;
                          this.notification = res.data ? 'FAQ post updated successful' : 'FAQ post failed to update';
                          this.actionClass = res.data  ? 'success' : 'error';
                       

                      } catch (error) {
                          this.notification = 'FAQ Post failed to update. Contact the admin';
                          this.actionClass = 'error';
                          this.loading = false;
                          return error.response;
                      }

          },
          cancel () {
           this.$router.push('/admin/admin-FAQs');
          },

    }

}
</script>

<style>
 .v-select-list > .v-list-item > .v-list-item__content > .v-list-item__title {
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.87);
}
</style>